export const getOpponentsNames = (brackets: Bracket) => {
  const opponentInfoArray: Match[] = [];
  for (const column in brackets) {
    const columnData = brackets[column];
    for (const match in columnData) {
      const matchData = columnData[match];
      opponentInfoArray.push(matchData);
    }
  }
  return opponentInfoArray;
};
