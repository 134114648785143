import {useRef, useState} from 'react';

// Components
import Portal from './Portal';

// Helpers
import useClickOutside from '../TableTournament/useCickOutside';

// Styles
import {
IconWrapper, ModalButton, ModalContent, ModalWrapper
} from './styled';

// Assets
import {ReactComponent as CloseIcon} from '../../assets/svg/close.svg';

interface VideoModal {
  btnText: string;
  children: React.ReactNode;
}

const VideoModal: React.FC<VideoModal> = ({btnText, children}) => {
  const [hidden, setHidden] = useState<boolean>(true);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutside(wrapperRef, () => {
    setHidden(true);
  });

  const handleOpenClick = () => {
    setHidden(!hidden);
  };

  return (
    <>
      <ModalButton onClick={handleOpenClick}>{btnText}</ModalButton>
      {!hidden ? (
        <Portal>
          <ModalWrapper ref={wrapperRef}>
            <ModalContent>
              <IconWrapper onClick={handleOpenClick}>
                <CloseIcon style={{width: '30px', height: '30px'}}/>
              </IconWrapper>
              {children}
            </ModalContent>
          </ModalWrapper>
        </Portal>
      ) : null}
    </>
  );
};

export default VideoModal;
