import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { primaryPetrol } from '../../assets/colors';
import { EnvelopeIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useInput } from '../../hooks/useInput';
import { Flex } from '../../style/Custom';
import { Button } from '../common/Button/Button';
import { Input } from '../common/Input/Input';
import { actionUserLogin } from '../../redux/actions/user';
import { UnderLineInput } from '../common/UnderLineInput/UnderLineInput';

import { RoundedButton } from '../common/RoundedButton/roundedButton';
import { BackgroundColor } from 'styled-icons/foundation';

const LoginStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    justify-content: center;
    width: 100%;
  }
`;

const Box = styled.div`
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    justify-content: flex-start;
    width: 100%;
    height:100%;
    h2 {
      margin-bottom: 30px;
    }
  }
`;
const CreateAccount = styled.span`
  cursor: pointer;
  color: ${primaryPetrol};
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.06em;
  text-decoration-line: underline;
  margin-top:auto;
  margin-left:auto;

  &:hover {
    color: #f95050;
  }
`;
const ForgotPassword = styled.p`
  cursor: pointer;
  font-weight: 300;
  font-size: 16px;
  color: ${primaryPetrol};
  line-height: 17px;
  text-align: right;
  letter-spacing: 0.06em;
  margin: 10px 0;
  margin-left: auto;

  &:hover {
    color: #f95050;
  }
`;
const CreateAccountWrapper = styled.div`
@media only screen and (max-width: 768px) {
  position:absolute;
  width:100%;
  padding:40px;
  bottom:0px;
  left:0px;
}
`

const Title = styled.h2`
  font-family: resolve;
  font-weight: 700;
  font-size: 24px;
  text-align:center;
  letter-spacing: 0.016em;
  color: white;
  margin-bottom: 26px;
`;
export const Login: FC = () => {
  const [loginError, setLoginError] = useState("")
  const firstLoad = useRef(true);
  useEffect(() => {
    if(firstLoad.current && user_email && user_id) {
      firstLoad.current = false;
      navigate('/account');
    } else {
      window.localStorage.removeItem('token');
    }
  });

  const email = useInput('');
  const password = useInput('');
  const dispatch = useAppDispatch();
  const { isLoading, error, user_email, user_id } = useAppSelector(
    ({ user }) => user,
  );
  const navigate = useNavigate();
  const buttonLoginHandlerClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    dispatch(
      actionUserLogin({
        user_email: email.value.toLocaleLowerCase(),
        password: password.value,
      }),
    ).then((res) => {
      if (res.type === "user/actionUserLogin/rejected") {
        let error = ""
        if (res.payload[0].length === 1) {
          error = res.payload.replaceAll("_", " ")
        } else error = res.payload[0].replaceAll("_", " ")
        setLoginError(error)
      } else {
        navigate("/")
      }
    })
  };

  if(isLoading) {
    return <h1>Loading...</h1>;
  }

  const passwordBoxErrorStyle = { marginBottom: 33 };

  return (
    <LoginStyled>
      <Box>
        <Title>Sign in </Title>
        {/* <Input {...email} type={'text'} placeholder="" label={'Email'} />
        <Input
          style={error ? passwordBoxErrorStyle : {}}
          {...password}
          type="password"
          placeholder=""
          label="Password"
          errorString={error!}
        /> */}
        <UnderLineInput
        {...email}
        placeholder="Email"
        name="email"
        inputStyle={{"padding-left": "30px", "padding-right": "30px", "text-align": "center"}}
        type="email"
        icon = {<EnvelopeIcon className='h-5 w-5 text-white'></EnvelopeIcon>}
        />

        <UnderLineInput
        {...password}
        placeholder="Password"
        name="password"
        inputStyle={{"padding-left": "30px", "padding-right": "30px", "text-align": "center"}}
        type="password"
        icon = {<LockClosedIcon className='h-5 w-5 text-white'></LockClosedIcon>}
        error = {loginError}
        />
        {/* <Flex flex_direction="column">
          <Button
            style={{ marginBottom: 18 }}
            type="submit"
            onClick={buttonLoginHandlerClick}
          >
            Continue
          </Button>
        </Flex> */}
        <ForgotPassword onClick={() => navigate('/forgotpassword')}>
          Forgot password?
        </ForgotPassword>
        <RoundedButton
          style={{ marginTop: 18, marginBottom: "auto", backgroundColor: "red"}}
          type="submit"
          text="Sign in"
          onClick={buttonLoginHandlerClick}><></></RoundedButton>
        <br />
          <CreateAccountWrapper>
          <Flex>
          <p style={{marginBottom: 0}}>Don’t have an account?</p>
        <CreateAccount onClick={() => navigate('/registration')}>
          Create account
        </CreateAccount>
        </Flex>
        </CreateAccountWrapper>
      </Box>
    </LoginStyled>
  );
};
