import { useState } from 'react';
import { useParams } from 'react-router-dom';

// Helpers
import { getOpponentsNames } from '../../../helpers/getOpponentsNames';
// Hooks
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import $api from '../../../http';
import { tableTournamentActionFetch } from '../../../redux/actions/tournament';
// Components
import Snackbar, { SnackBarProps } from '../../Snackbar';
// Styles
import {
  ContentWrapper,
  ErrorMessage,
  InputVideoUpload,
  StyledButton,
  StyledSelect,
} from './styled';

const UploadVideo = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarData, setSnackbarData] = useState<{
    type: SnackBarProps['type'];
    message: SnackBarProps['message'];
    hide: SnackBarProps['hide'];
  }>({
    message: '',
    type: 'success',
    hide: true,
  });
  const [errors, setErrors] = useState({
    selectedOption: '',
    selectedFile: '',
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const { _id, brackets } = useAppSelector(
    ({ tableTournament }) => tableTournament,
  );

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = () => {
    if (validateForm() && selectedFile !== null) {
      const formData = new FormData();
      formData.append('video', selectedFile);
      setIsLoading(true);
      const config = {
        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setUploadProgress(progress);
          }
        },
      };
      $api
        .post(
          `/tournaments/${_id}/match/${selectedOption}/video`,
          formData,
          config,
        )
        .then((response) => {
          if (response.status === 201) {
            setSnackbarData({
              message: 'Video has been uploaded',
              type: 'success',
              hide: false,
            });
            id && dispatch(tableTournamentActionFetch(id));
          } else {
            setSnackbarData({
              message: 'There was an error. Please try again later',
              type: 'error',
              hide: false,
            });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setSnackbarData({
            message: 'There was an error. Please try again later',
            type: 'error',
            hide: false,
          });
          setIsLoading(false);
        });
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      selectedOption: '',
      selectedFile: '',
    };
    if (selectedOption === '') {
      newErrors.selectedOption = 'Select a match, please.';
      valid = false;
    }
    if (!selectedFile) {
      newErrors.selectedFile = 'Upload a file.';
      valid = false;
    }
    setErrors(newErrors);
    if (!valid) {
      setTimeout(() => {
        setErrors({
          selectedOption: '',
          selectedFile: '',
        });
      }, 2000);
    }
    return valid;
  };

  const hideSnackBar = () => {
    setSnackbarData({ message: '', type: 'success', hide: true });
  };

  return (
    <ContentWrapper>
      <p>Select a Match:</p>
      <StyledSelect value={selectedOption} onChange={handleSelectChange}>
        <option value="">Select</option>
        {getOpponentsNames(brackets)
          .filter((option) => option.finishMatch)
          .map((option, index) => (
            <option key={index} value={option.id}>
              {`${option.opponent1.name} - ${option.opponent2.name}`}
            </option>
          ))}
      </StyledSelect>
      {errors.selectedOption && (
        <ErrorMessage>{errors.selectedOption}</ErrorMessage>
      )}
      <InputVideoUpload
        type="file"
        accept="video/*"
        onChange={handleFileChange}
      />
      {errors.selectedFile && (
        <ErrorMessage>{errors.selectedFile}</ErrorMessage>
      )}
      <Snackbar
        hide={snackbarData.hide}
        type={snackbarData.type}
        message={snackbarData.message}
        timeout={1000}
        callback={hideSnackBar}
      />
      {selectedFile && (
        <div>
          <p>Selected File: {selectedFile.name}</p>
        </div>
      )}
      <StyledButton onClick={handleUpload}>Save</StyledButton>
      {isLoading && (
        <label>
          File progress: <progress value={uploadProgress} max="100" />
        </label>
      )}
    </ContentWrapper>
  );
};

export default UploadVideo;
