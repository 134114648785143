import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RootState } from '../../redux';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { actionChangeListTournament } from '../../redux/reducer/listTournamentReducer';

// Components
import { ClassicTournamentForm } from './ClassicTournamentForm';

// Helpers
import { createTournament } from '../../services/tournament';
import { Flex } from '../../style/Custom';
import Snackbar from '../Snackbar';

// Styles
import {
  Button,
  ButtonBox,
  CommonWrapper,
  Input,
  InputBox,
  NextButton
} from './CreateTournamentForm.styled';
import { EloTournamentForm } from './EloTournamentForm';

const primaryPetrol = "#225f78"

interface ReqData {
  creator: string | null;
  place: any;
  name: string;
  additional: string;
  drafts: boolean;
  kFactor: number;
  date: string;
  rules: string;
  selectedOption: number;
  participants?: number;
  preQualification: boolean;
}

export const CommonTournamentForm = () => {

  const { user_id, roles } = useSelector(({ user }: RootState) => user);
  const [formData, setFormData] = useState({
    name:
      !roles || roles?.[0] == 'Guest'
        ? `Guest user - ${new Date().toLocaleString('sv', {year:'numeric', month:'numeric', day:'numeric', hour:'numeric', minute:'numeric', second:'numeric'}).slice(0, 16)}`
        : '',
    additional: '',
    drafts: false,
    kFactor: 32,
    date: new Date().toLocaleString('sv', {year:'numeric', month:'numeric', day:'numeric', hour:'numeric', minute:'numeric', second:'numeric'}).slice(0, 16),
    rules: 'Classic',
    selectedOption: 2,
  });
  const [error, setError] = useState({
    hide: true,
    message: '',
  });
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchValue)
      loadOptions(searchValue, (fetchedOptions) => {
        setOptions(fetchedOptions);
      });
  }, [searchValue]);

  useEffect(() => {
    if (!roles || roles?.[0] == 'Guest') {
      loadOptions('orlando', (fetchedOptions) => {
        setOptions(fetchedOptions);
      });
    }
  });

  const loadOptions = (inputValue, callback) => {
    const headers = { 'Accept-Language': 'en' };
    axios
      .get(`https://nominatim.openstreetmap.org/search`, {
        params: {
          q: inputValue,
          format: 'json',
        },
        headers: headers,
      })
      .then((response) => {
        const mappedOptions = response.data.map((result) => ({
          label: result.display_name,
          value: result.place_id,
        }));
        setOptions(mappedOptions);
        callback(mappedOptions);
      })
      .catch((error) => {
        console.error('Error fetching data from OSM API:', error);
        setOptions([]);
        callback([]);
      });

    if (options.length === 0) {
      setOptions([
        {
          label: inputValue,
          value: inputValue,
        },
      ]);
      callback([
        {
          label: inputValue,
          value: inputValue,
        },
      ]);
    }
  };

  const handleSubmit = async() => {
    const isValid = true;
    if (!isValid) {
      setError({
        message: 'Name is required',
        hide: false,
      });
      return;
    }
    const reqData: ReqData = {
      ...formData,
      creator: user_id,
      place: options[0]?.label,
      preQualification: false,
    };
    if (formData.rules === 'ELO') {
      reqData.participants = +formData.selectedOption;
    }
    await createTournament(reqData)
      .then((resp) => {
        dispatch(actionChangeListTournament('Upcoming'));
        navigate(`/tournament/${resp._id}`);
      })
      .catch((error) => {
        setError({
          message: error?.response?.data?.message || '',
          hide: false,
        });
      });
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleTimeChange = e => {
    const newFormData = {...formData}
    newFormData.date = newFormData.date.split("T")[0] + "T" + e.target.value
    setFormData(newFormData)
  }
  const handleDateChange = e => {
    const newFormData = {...formData}
    newFormData.date = e.target.value + "T" + newFormData.date.split("T")[1]
    setFormData(newFormData)
  }
  const handleSelectChange = (selectedOption) => {
    setSearchValue(selectedOption);
  };

  const handleInputChange = (inputValue) => {
    setSearchValue(inputValue);
  };
  const resetError = () => {
    setError({
      hide: true,
      message: '',
    });
  };
  return (
    <CommonWrapper>
      <>
        <ClassicTournamentForm
          checkType={formData.rules}
          formData={formData}
          handleSubmit={handleSubmit}
          handleChangeValue={handleChangeValue}
          handleTimeChange ={handleTimeChange}
          handleDateChange = {handleDateChange}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
          searchValue={searchValue}
          options={options}
        />
        <InputBox>
          {/* <label>Tournament rules</label> */}
          <Flex
            role={'group'}
            style={{ width: '50%', marginTop: 10 }}
            gap="10px"
          >
            {/* <Flex>
              <Input
                type="radio"
                placeholder=""
                name="rules"
                value="Classic"
                checked={formData.rules === 'Classic'}
                onChange={handleChangeValue}
              />
              <label>Classic</label>
            </Flex> */}
            {roles?.includes('Admin') || roles?.includes('MasterAdmin') ? (
              <>
                <Flex>
                  <Input
                    type="radio"
                    placeholder=""
                    name="rules"
                    value="Team"
                    checked={formData.rules === 'Team'}
                    onChange={handleChangeValue}
                  />
                  <label>Team</label>
                </Flex>
                <Flex>
                  <Input
                    type="radio"
                    placeholder=""
                    name="rules"
                    value="ELO"
                    checked={formData.rules === 'ELO'}
                    onChange={handleChangeValue}
                  />
                  <label>ELO</label>
                </Flex>
              </>
            ) : null}
          </Flex>
          {formData.rules === 'ELO' ? (
            <EloTournamentForm
              kFactor={formData.kFactor}
              handleChangeOptions={handleChangeValue}
            />
          ) : null}
        </InputBox>
      </>
      <Snackbar
        message={error.message}
        type="error"
        timeout={800}
        hide={error.hide}
        callback={resetError}
      />
      <ButtonBox>
        {/* {roles && roles?.[0] != 'Guest' && (
          <Button
            style={{ marginRight: 15 }}
            color="linear-gradient(90deg, rgba(146,30,118,1) 31%, rgba(121,35,115,1) 58%, rgba(89,40,119,1) 80%), #c9302e"
            type="submit"
          >
            Save draft
          </Button>
        )} */}
        {/* <Button
          type="submit"
          onClick={handleSubmit}
        >
          <p>Create tournament</p>
          <ArrowRightIcon className='h-5 w-5 ml-auto'></ArrowRightIcon>
        </Button> */}
      </ButtonBox>
      <NextButton
          color={primaryPetrol}
          type="submit"
          onClick={handleSubmit}
        >
          <p className='mx-auto'>Create tournament</p>
          <ArrowRightIcon className='h-5 w-5'></ArrowRightIcon>
        </NextButton>
    </CommonWrapper>
  );
};
