import { useEffect, useState } from 'react';

// Helpers
import { getListOfMatches } from '../../helpers/getListOfMatches';
import { useAppSelector } from '../../hooks/redux';
import { ContentWrapper } from '../common/UploadVideoInput/styled';
// Styles
import { Link, LinkWrapper, Title } from './styled';

export const DownloadVideo = () => {
  const { brackets } = useAppSelector(({ tableTournament }) => tableTournament);
  const [matches, setMatches] = useState<Match[]>([]);

  useEffect(() => {
    setMatches(getListOfMatches(brackets));
  }, [brackets]);

  const filteredMatches = matches.filter(
    (match) => match?.videoId.length !== 0,
  );

  return (
    <ContentWrapper>
      {filteredMatches.length === 0 ? (
        <Title>No videos available</Title>
      ) : (
        filteredMatches.map((match) =>
          match.videoId.map((i) => (
            <LinkWrapper key={match.id}>
              <span>{`${match.opponent1.name} vs ${match.opponent2.name}`}</span>
              <Link
                href={`${process.env.REACT_APP_API_URL}/tournaments/match/video/${i}`}
              >
                See the Video
              </Link>
            </LinkWrapper>
          )),
        )
      )}
    </ContentWrapper>
  );
};
